<template>
    <header>
        <div class="header">

            <div class="header-top d-padding">
                <div class="container-fluid">
                    <div class="header-top__content">
                        <div v-if="$isDesktopScreen" class="header-top__info">
                            <h6 class="header-top__info-offer">Ein Angebot der Stadt</h6>
                            <img src="/assets/icon-wolf.png" alt="Logo der Stadt Wolfsburg">
                            <h6 class="header-top__info-wolf">Wolfsburg</h6>
                        </div>

                        <keyword-search v-if="$isDesktopScreen" class="ml-auto" :initialValue="searchWord" @searchWordChanged="searchWordChanged"/>

                        <button v-if="$isDesktopScreen" @click="showFeedbackModal" class="btn btn-sm btn-lime btn-w-icon icon-right ml20" title="Feedback übermitteln">
                            Feedback
                            <span class="material-icons-outlined" aria-hidden="true">chat_bubble_outline</span>
                        </button>

                        <div :class="['language-switch',(isHome && $isMobileScreen)?'special-positioning':'']"></div>
                    </div>
                </div>
            </div>

            <div v-if="isHome && $isMobileScreen" class="header-top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 header-top__info">
                            <h6 class="header-top__info-offer">Ein Angebot der Stadt</h6>
                            <img src="/assets/icon-wolf.png" alt="Logo der Stadt Wolfsburg">
                            <h6 class="header-top__info-wolf">Wolfsburg</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-bottom" v-if="!$isMobileScreen">
                <div class="container-fluid">
                    <div class="navbar">
                        <button class="no-btn brand p-0" @click="goHome">
                            <h1 class="logo do-not-translate">
                                <img src="/assets/logo/logo50.png" alt="Das Logo für die Wolfsburger Lupe - eine Kreuzung aus einem Wolf und einer Lupe"/>
                                <div class="text do-not-translate">Wolfsburger <span>Lupe</span></div>
                            </h1>
                        </button>

                        <desktop-nav/>

                    </div>
                </div>
            </div>

            <div :class="['header-bottom', sticky ? 'sticky' : '']" v-show="(isHome || showHeader) && $isMobileScreen">
                <div class="container-fluid">
                    <button class="no-btn brand p-0"  @click="goHome">
                        <h1 class="logo do-not-translate">
                            <img src="/assets/logo/logo50.png" alt="Das Logo für die Wolfsburger Lupe - eine Kreuzung aus einem Wolf und einer Lupe"/>
                            <div class="text do-not-translate">Wolfsburger <span>Lupe</span></div>
                        </h1>
                    </button>

                    <button @click="showFeedbackModal" aria-label="Feedback übermitteln" class="btn btn-round feedback-btn">
                        <span class="material-icons-outlined" aria-hidden="true">chat_bubble_outline</span>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="$isMobileScreen" class="mobile">
            <mobile-nav/>
        </div>

    </header>
</template>

<script>
    import { bus } from '@/main';
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';

    export default {
      name: 'Header',
      components: {
       KeywordSearch: () => import('@/components/controls/KeywordSearch'),
       DesktopNav: () => import('./components/DesktopNav'),
       MobileNav: () => import('./components/MobileNav'),
   },
   mixins: [screenSizeMixin],
   data() {
    return {
        searchWord: '',
        sticky: false,
    }
},
mounted () {
    this.sticky = window.scrollY >= 42;
    window.addEventListener('scroll', this.scrollHandler);
},
beforeDestroyed () {
    window.removeEventListener('scroll', this.scrollHandler)
},
computed: {
    isHome() {
      return this.$route.path === '/' ? true : false;
  },
  showHeader() {
      return this.$route.meta.showHeader === true ? true : false;
  },
},
methods: {
    goHome(){
        if(this.isHome){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else{
            this.$router.push('/')
        }
    },
    searchWordChanged(word){
        this.$router.push({ name: 'Search', query: { keyword: word } });
        this.searchWord = "";
    },
    scrollHandler () {
      this.sticky = window.scrollY >= 42
  },
  showFeedbackModal() {
    bus.$emit('show-feedback-modal', true);
},
toogleLayoutDirection(){
    bus.$emit('toggle-layout-direction');
},
}
}
</script>
<style lang="scss" scoped>

    .logo{

        .text{
            display: inline;            
            text-transform: uppercase;

            @media (max-width: 370px) {
                font-size: 16px;
            }
        }

        img{
            margin-right: 10px;

            @media (max-width: 370px) {
                height: 35px;
                margin-right: 5px;
            }
        }
    }

    body[dir="rtl"] {
        .logo img{
           margin-right: 0px;
           margin-left: 10px; 
       }

   }

</style>